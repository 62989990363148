.icon-row {
  margin: 5em 0;
  text-align: center; }
  .icon-row h2 {
    font-size: 1em;
    margin-bottom: 2em; }
  .icon-row.first {
    margin-top: 0;
    background: #fff;
    padding: 3em;
    position: relative;
    text-align: left; }
    .icon-row.first .section-title {
      text-align: left; }
    .icon-row.first .content-wrap {
      display: block;
      margin: 1em 0;
      text-align: center; }
      @media (max-width: 800px) {
        .icon-row.first .content-wrap {
          display: block; } }
      .icon-row.first .content-wrap .image {
        display: inline-block;
        margin-right: 0.6em; }
        .icon-row.first .content-wrap .image img {
          max-width: 200px;
          height: auto; }
      .icon-row.first .content-wrap .text {
        display: inline-block; }
  .icon-row .row {
    justify-content: space-around; }
  @media (max-width: 768px) {
    .icon-row {
      text-align: center; } }
  .icon-row h3 {
    color: #50554b;
    font-weight: normal;
    font-size: 1.5em;
    text-decoration: none; }
  .icon-row p {
    color: #50554b;
    text-decoration: none;
    font-size: 0.9em; }
  .icon-row ul li {
    margin: 0.5em 0;
    list-style-type: none; }
  .icon-row .image {
    max-width: 100%;
    height: auto;
    margin-bottom: 0.8em; }
    .icon-row .image img {
      max-width: 100%;
      max-height: 90px; }
  .icon-row .icon-holder {
    text-align: center; }
    .icon-row .icon-holder i {
      font-size: 4em;
      color: #50554b; }
